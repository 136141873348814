import "@/styles/globals.css"
import { api } from "@/utils/api"
import { supabase } from "@/utils/supabase"
import { HighlightInit } from "@highlight-run/next/client"
import { SessionContextProvider } from "@supabase/auth-helpers-react"
import { type AppType } from "next/app"
import { Inter } from "next/font/google"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "700"],
})

const isProduction = process.env.NODE_ENV === "production"
const readyToTrackAnalyticsInProd =
  isProduction && typeof window !== "undefined"

if (readyToTrackAnalyticsInProd) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: "/ingest",
    ui_host: "https://us.posthog.com",
  })
}

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <main className={inter.className}>
      {isProduction && (
        <HighlightInit
          projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
          serviceName="epicscri.pt-client"
          tracingOrigins
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
        />
      )}

      <PostHogProvider>
        <SessionContextProvider
          initialSession={pageProps.initialSession}
          supabaseClient={supabase}
        >
          <Component {...pageProps} />
        </SessionContextProvider>
      </PostHogProvider>
    </main>
  )
}

export default api.withTRPC(MyApp)
